import React, { Component } from 'react';
import { Layout, BackTop } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import { siteConfig, themeConfig } from '../../settings/config';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';

import ErrorBoundary from "../../helpers/errorBoundary";
import AppContext from '../../AppContext';

import './global.css';

const { Content, Footer } = Layout;
export class AppTemplate extends Component {
  static contextType = AppContext;
  
  toggleAll(width,height) {
    this.context.toggleResize(width, height);
  }

  render() {
    const { url } = this.props.match;
    const { location } = this.props;
    const { height } = this.props;
    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize => this.toggleAll(windowSize.windowWidth, windowSize.windowHeight)}
              />
            </Debounce>
            <Topbar url={url} location={location}/>
            <BackTop />
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout id="content-main-layout"
                className="isoContentMainLayout"
                style={{
                  marginTop: '70px',
                  height: height
                }}
              >
                <BackTop target={()=>document.getElementById('content-main-layout')}/>
                <Content id="content-main"
                  className="isomorphicContent"
                  style={{
                    flexShrink: '0',
                    position: 'relative'
                  }}
                >
                  <ErrorBoundary> 
                    {this.props.children}
                  </ErrorBoundary>
                </Content>
                <Footer>
                  {siteConfig.footerText}
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default AppTemplate
