import React from 'react';
import { Route, Redirect, Switch, withRouter} from 'react-router-dom';

import AppContext from './AppContext';
import asyncComponent from './helpers/AsyncFunc';
import r7 from './containers/r7/App';
import r4 from './containers/r4/App';

const state_routing = (state, state_order, location) => {

    /* The Routes for state redirection */
    let state_routes = { 
      'sq': '/security/security-questions',
      'token': '/security/device-token'
    }

    /* Loop over the state order*/
    for ( let s of state_order){
        /* Check State Status */
        if ( (state[s] !== undefined && state[s] === 0 && state_routes[s] !== undefined) || (state[s] === undefined && state_routes[s] !== undefined) ){
            /* Check we aren't currently there */
            if(location !== state_routes[s]){
                return <Redirect key="stateroute" to={state_routes[s]} />;
            }else{
                return false
            }
        }
    }

    return false;
}

class Routes extends React.Component{

    render(){
        const context = {...this.props.context, navigate: this.props.history.push, history: this.props.history}
        return (
            <AppContext.Provider value={context}>
                <Switch>

                    { /* Unauthenticated Routes */}
                    <Route exact path={'/signin'} component={asyncComponent(() => import('./containers/Page/signin'))} />
                    <Route exact path={'/forgotpassword'} component={asyncComponent(() => import('./containers/Page/forgotpassword'))} />
                    <Route key="activate" path="/user/activate/:code?" component={asyncComponent(() => import('./containers/security/activate'))} />
                    <Route key='resetpassword' path='/resetpassword/:code?' component={asyncComponent(() => import('./containers/Page/forgotpassword'))} />
                    {!this.props.context.isAuthenticated? <Redirect to={{
                        pathname: "/signin",
                        state: { referrer: window.location.pathname }
                    }}/> : false}

                    {/* This manages the state based redirection */}
                    {this.props.context.user_state !== null ? state_routing(this.props.context.user_state, this.props.context.state_order, window.location.pathname) : false}

                    {/* Security Routes */}
                    <Route key="securityquestions" path="/security/security-questions" exact component={asyncComponent(() => import('./containers/security/securityQuestions'))} />
                    <Route key="devicetokens" path="/security/device-token" exact component={asyncComponent(() => import('./containers/security/deviceTokens'))} />

                    {/* Authenticated Routes */}
                    <Route path="/r7" component={r7} />
                    <Route path="/r4" component={r4} />
                    <Redirect to={{pathname: this.props.context.defaultPage}}/>
                </Switch>
            </AppContext.Provider>
        )
    }
}

export default withRouter(Routes);