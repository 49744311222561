import React, { Component } from 'react';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../AppContext';

import { checkExists } from '../../helpers/utilities';

class TopbarUser extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  handleLogout = () => {
    this.context.signout_user();
  }
  render() {

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <span className="isoDropdownLink" style={{pointerEvents: 'none', opacity: 0.6}}>
          <IntlMessages id="topbar.settings" />
        </span>
        <span className="isoDropdownLink" onClick={this.handleLogout} >
          <IntlMessages id="topbar.logout" />
        </span>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          {checkExists(this.context, 'user_info', 'photo_url') && this.context.user_info.photo_url !== null ? <img alt="user" src={this.context.user_info.photo_url} /> : <FontAwesomeIcon className={'fa-lg'} icon={['fas', 'user-alt']} /> }
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default TopbarUser;
