import React, { Component } from 'react';
import clone from 'clone';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import options from '../Base/appRoutes';
import Scrollbars from '../../components/utility/customScrollBar.js';
import Menu from '../../components/uielements/menu';
import IntlMessages from '../../components/utility/intlMessages';
import SidebarWrapper from './sidebar.style';
import Logo from '../../components/utility/logo';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDefaultPath } from '../../helpers/urlSync';

import AppContext from '../../AppContext';

const SubMenu = Menu.SubMenu;
const { Sider } = Layout;


class Sidebar extends Component {
  static contextType = AppContext;
  
  componentDidMount() {
    if(this.context.sbCurrent.length === 0) {
      this.context.changeCurrent(getDefaultPath(window.location.pathname));
      this.context.changeOpenKeys(getDefaultPath(window.location.pathname));
    }
  }

  handleClick = (e) => {
    this.context.changeCurrent([e.key]);
    if (this.context.view === 'MobileView') {
      setTimeout(() => {
        this.toggleCollapsed();
        this.toggleOpenDrawer();
      }, 100);
    }
  }

  toggleOpenDrawer() {
    this.context.toggleOpenDrawer()
  }

  toggleCollapsed = () => {
    this.context.toggleCollapsed();
  }

  changeOpenKeys(nextOpenKeys) {
    this.context.changeOpenKeys(nextOpenKeys);
  }

  onOpenChange = (newOpenKeys) => {
    this.changeOpenKeys(newOpenKeys);
  }

  getMenuItem = ({ singleOption, submenuStyle, submenuColor }) => {
    const { key, path, label, leftIcon, product, permissions, onMenu, children } = singleOption;
    if (this.context.permissions[this.context.active_role] !== undefined 
        && permissions.some(r=> this.context.permissions[this.context.active_role].indexOf(r) >= 0)
        && onMenu) {
      let url = '/'+product;
      if (children) {
        return (
          <SubMenu
            key={key}
            title={
              <span className="isoMenuHolder" style={submenuColor}>
                <FontAwesomeIcon icon={leftIcon} />
                &nbsp;
                <span className="nav-text text-capitalize">
                  <IntlMessages id={label} />
                </span>
              </span>
            }
          >
            {children.map(child => {
              if (this.context.permissions[this.context.active_role] !== undefined 
                  && child.permissions.some(r=> this.context.permissions[this.context.active_role].indexOf(r) >= 0)
                  && child.onMenu) {
                let curl = child.product;
                const linkTo = child.withoutDashboard
                  ? `/${child.path}`
                  : `/${curl}/${child.path}`;

                return (
                  <Menu.Item style={submenuStyle} key={child.key}>
                    <Link style={submenuColor} to={linkTo}>
                      <span className="nav-text text-capitalize">
                        <IntlMessages id={child.label} />
                      </span>
                    </Link>
                  </Menu.Item>
                );
                } else {
                return false;
              }
            })}
          </SubMenu>
        );
        
      }
      return (
        <Menu.Item key={key}>
          <Link to={`${url}/${path}`}>
            <span className="isoMenuHolder" style={submenuColor}>
              <FontAwesomeIcon icon={leftIcon} />
              &nbsp;
              <span className="nav-text text-capitalize">
                <IntlMessages id={label} />
              </span>
            </span>
          </Link>
        </Menu.Item>
      );
    } else {
      return false;
    }
  };
  render() {
    const collapsed = clone(this.context.sbCollapsed) && !clone(this.context.sbOpenDrawer);
    const mode = collapsed === true ? 'vertical' : 'inline';
    const customizedTheme = themes[themeConfig.theme];
    const styling = {
      backgroundColor: customizedTheme.backgroundColor
    };
    const submenuStyle = {
      backgroundColor: 'rgba(0,0,0,0.3)',
      color: customizedTheme.textColor
    };
    const submenuColor = {
      color: customizedTheme.textColor
    };
    var openKeys = collapsed ? false : {openKeys: this.context.sbOpenKeys}
    return (
      <SidebarWrapper>
        <Sider
          trigger={null}
          collapsible={true}
          collapsed={collapsed}
          width={240}
          className="isomorphicSidebar"
          style={styling}
        >
          <Logo collapsed={collapsed} />
          <Scrollbars style={{ height: this.context.height - 70 }}>
            <Menu
              onClick={this.handleClick}
              theme="dark"
              className="isoDashboardMenu"
              mode={mode}
              {...openKeys}
              selectedKeys={this.context.sbCurrent}
              onOpenChange={this.onOpenChange}
            >
              {options.map(singleOption =>
                this.getMenuItem({ submenuStyle, submenuColor, singleOption })
              )}
            </Menu>
          </Scrollbars>
        </Sider>
      </SidebarWrapper>
    );
  }
}

export default Sidebar;
