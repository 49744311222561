import logoDark from '../image/acpi-logo.svg'
import logoLight from '../image/acpi-logo white.svg'
import logoIcon from '../image/acpi-icon.svg'

// import logoDark from '../image/Wells-FP-logo-ai-file.png'
// import logoLight from '../image/Wells-FP-logo-ai-file-White.png'
// import logoIcon from '../image/Wells-FP-Acorn-logo.png'

var config = {}
var default_config = {
  apiGateway: {
    URL: "",
    REGION: ''
  },
  jwt: {
    sign_key: "sign_key_here"
  },
  defaultLanguage: "english"
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};

const siteConfig = {
  title: 'Aligned Capital',
  //title: 'Wells Financial',
  signInlogo: logoDark,
  siteLogo: logoLight,
  siteIcon: logoIcon,
  defaultPage: '/',
  footerText: '© Four Eyes Financial 2019. All rights reserved.',
  passwordLength: 8
};

try {
  var local = require("./config_local.js");
  config = {...default_config, ...local.config}
} catch(e) {
  config = default_config
}

export { config, siteConfig, themeConfig }

