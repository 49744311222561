import React, { Component } from 'react';
import Popover from '../../components/uielements/popover';
import IntlMessages from '../../components/utility/intlMessages';
import { getDefaultPath } from '../../helpers/urlSync';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { Button } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from '../../AppContext';


class TopbarRole extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    if(Object.keys(this.context.roles).length > 1) {
      this.setState({ visible: !this.state.visible });
    }
  }
  changeRole = (rn) => {
    if(rn !== this.context.active_role) {
      this.context.changeActiveRole(rn);
      this.hide();
      this.context.navigate(this.context.roles[rn]['page']);
      // to handle breadcrumb
      this.context.changeCurrent(getDefaultPath(window.location.pathname));
      this.context.changeOpenKeys(getDefaultPath(window.location.pathname));
    }
  }
  render() {
    let multiRole = Object.keys(this.context.roles).length > 1;

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        {Object.keys(this.context.roles).map((rn, index) => {
          let roleStyle={};
          if(rn === this.context.active_role) {
            roleStyle={pointerEvents: 'none', opacity: 0.6};
          }
          return(<span key={index} className="isoDropdownLink text-capitalize" style={roleStyle} onClick={() => this.changeRole(rn)} >
            <IntlMessages id={rn} />
          </span>)
        })}
      </TopbarDropdownWrapper>
    );
    let color=this.context.roles[this.context.active_role].color ? this.context.roles[this.context.active_role].color : '#FFFFFF';
    const btnStyle = {backgroundColor: color}
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <Button style={btnStyle} disabled={!multiRole} shape='round'><span className='text-capitalize'>
          <IntlMessages  id={this.context.active_role} />{multiRole?<FontAwesomeIcon style={{marginLeft: '1em'}} icon={['fal', 'chevron-down']} /> : false}</span>
        </Button>
      </Popover>
    );
  }
}
export default TopbarRole;
