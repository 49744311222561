import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  // {
  //   key: 'staff',
  //   path: 'staff',
  //   product: 'r7',
  //   component: asyncComponent(() => import('../Firm/staff')),
  //   onMenu: true,
  //   label: 'sidebar.Staff',
  //   leftIcon: ["fal", "sitemap"],
  //   permissions: ['r7.all']
  // },
  {
    key: 'advisors',
    path: 'advisors',
    product: 'r7',
    //component: asyncComponent(() => import('../notfound')),
    component: asyncComponent(() => import('../Advisor/advisors')),
    onMenu: true,
    label: 'sidebar.contactsAdvisor',
    leftIcon: ["fal", "user-chart"],
    permissions: ['r7.all', 'r7.advisor.mgmt']
  },
  {
    key: 'dashboards',
    label: 'sidebar.dashboards',
    path: 'dashboards',
    product: 'r7',
    leftIcon: ["fal", "tachometer-average"],
    onMenu: true,
    bcDisabled: true,
    permissions: ['r7.all'],
    children: [
      {
        key: 'advisorreview',
        path: 'dashboards/advisorreview',
        regex: 'dashboards/advisorreview/:id?',
        exact: true,
        product: 'r7',
        label: 'sidebar.dashboards.advisorreview',
        component: asyncComponent(() => import('../Supervisor/advisorReviewDashboard')),
        onMenu: true,
        permissions: ['r7.all'],
      },
      {
        key: 'supervisordaily',
        path: 'dashboards/supervisordaily',
        product: 'r7',
        regex: 'dashboards/supervisordaily/:id?',
        exact: false,
        label: 'sidebar.dashboards.supervisordaily',
        component: asyncComponent(() => import('../Dashboards/SupervisorDaily')),
        onMenu: true,
        permissions: ['r7.all']
      },
      {
        key: 'advisorexceptionreport',
        path: 'dashboards/advisorreview/advisorexceptionreport',
        regex: 'dashboards/advisorreview/advisorexceptionreport/:id?',
        exact: true,
        product: 'r7',
        label: 'sidebar.dashboards.advisorreport',
        component: asyncComponent(() => import('../Supervisor/advisorExceptionReport')),
        onMenu: false,
        permissions: ['r7.all']
      },
      {
        key: 'tier1report',
        path: 'dashboards/advisorreview/tier1report',
        regex: 'dashboards/advisorreview/tier1report/:id?',
        exact: true,
        product: 'r7',
        label: 'sidebar.dashboards.advisorreport',
        component: asyncComponent(() => import('../Dashboards/Tier1SDReport')),
        onMenu: false,
        permissions: ['r7.all']
      },
      {
        key: 'sdadvisorreport',
        path: 'dashboards/advisorreview/sdadvisorreport',
        regex: 'dashboards/advisorreview/sdadvisorreport/:id?',
        exact: true,
        product: 'r7',
        label: 'sidebar.dashboards.advisorreport',
        component: asyncComponent(() => import('../Dashboards/SDAdvisorReport')),
        onMenu: false,
        permissions: ['r7.all']
      }
    ]
  },
  {
    key: 'documents',
    path: 'documents',
    product: 'r7',
    component: asyncComponent(() => import('../notfound')),
    onMenu: true,
    label: 'sidebar.documents',
    leftIcon: ["fal", "file"],
    permissions: ['r7.all']
  },
  {
    key: 'inquiries',
    path: 'inquiries',
    product: 'r7',
    component: asyncComponent(() => import('../inquiry/risk7')),
    onMenu: true,
    label: 'sidebar.inquiries',
    leftIcon: ["fal", "question-circle"],
    permissions: ['r7.inquiries.all'],
  },
  {
    key: 'inquiry',
    path: 'inquiries',
    regex: 'inquiry/:id',
    product: 'r7',
    component: asyncComponent(() => import('../../components/inquiry/inquiryInfo')),
    onMenu: false,
    label: 'inquiries',
    permissions: ['r7.inquiries.all']
  },
  {
    key: 'reports',
    label: 'sidebar.reports',
    path: 'reports',
    product: 'r7',
    leftIcon: ["fal", "file-chart-line"],
    onMenu: true,
    permissions: ['r7.all'],
    children: [
      {
        key: 'activity',
        path: 'reports/activity',
        product: 'r7',
        exact: false,
        component: asyncComponent(() => import('../Reports/reports')),
        onMenu: true,
        label: 'sidebar.activity',
        permissions: ['r7.all']
      },
      {
        key: 'supervisory',
        path: 'reports/supervisory',
        product: 'r7',
        exact: false,
        component: asyncComponent(() => import('../Reports/reports')),
        onMenu: true,
        label: 'sidebar.supervisory',
        permissions: ['r7.all']
      }
    ]
  },
  {
    key: 'products',
    path: 'products',
    product: 'r7',
    exact: false,
    component: asyncComponent(() => import('../Products/products')),
    onMenu: true,
    label: 'sidebar.products',
    leftIcon: ["fal", "boxes"],
    permissions: ['r7.all']
  },
  {
    key: 'search',
    label: 'sidebar.search',
    path: "search",
    product: 'r7',
    leftIcon: ["fal", "search"],
    onMenu: true,
    bcDisabled: true,
    roles: ['all'],
    permissions: ['r7.all'],
    children: [
      {
        key: 'transactions',
        path: 'search/transactions',
        product: 'r7',
        exact: false,
        onMenu: true,
        label: 'sidebar.transactions',
        component: asyncComponent(() => import('../Transactions/Transactions')),
        permissions: ['r7.all']
      },
      {
        key: 'clients',
        path: 'search/clients',
        product: 'r7',
        exact: false,
        onMenu: true,
        label: 'sidebar.clients',
        component: asyncComponent(() => import('../Clients/clients')),
        permissions: ['r7.all']
      }
    ]
  },
  {
    key: 'advgroups',
    path: 'advgroups',
    product: 'r7',
    component: asyncComponent(() => import('../Advisor/advgroups')),
    onMenu: true,
    label: 'sidebar.advGroups',
    leftIcon: ["fal", "users"],
    permissions: ['r7.advisor.mgmt']
  },
  {
    key: 'repcodes',
    path: 'repcodes',
    product: 'r7',
    component: asyncComponent(() => import('../RepCodes/repcodes')),
    onMenu: true,
    label: 'sidebar.repCodes',
    leftIcon: ["fal", "registered"],
    permissions: ['r7.repcode.mgmt']
  },
  {
    key: 'users',
    path: 'users',
    product: 'r7',
    component: asyncComponent(() => import('../Admin/users')),
    onMenu: true,
    label: 'sidebar.users', //replace with en_US
    leftIcon: ["fal", "user"],
    permissions: ['app.user.mgmt'],
  },
  // {
  //   key:"roles",
  //   path:"roles",
  //   product:'r7',
  //   component: asyncComponent(() => import('../Admin/roles')),
  //   onMenu:true,
  //   label:'sidebar.roles',
  //   leftIcon:["fal","key"],
  //   permissions: ['app.role.mgmt']
  // }
];
export default routes;
