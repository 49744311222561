import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Layout } from "antd";
import TopbarUser from "./topbarUser";
import TopbarRole from "./topbarRole";
import TopbarWrapper from "./topbar.style";
import IntlMessages from '../../components/utility/intlMessages';
import themes from "../../settings/themes";
import { themeConfig } from "../../settings/config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb } from 'antd';

import AppContext from '../../AppContext';

const { Header } = Layout;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
  static contextType = AppContext;

  toggleCollapsed = () => {
    this.context.toggleCollapsed();
  }

  displayBreadCrumb = () => {
    var i = 0
    return (
      <Breadcrumb>
        {this.context.breadcrumb.map(bc => {
          return (<Breadcrumb.Item key={i++}>
            <span className="text-capitalize">{bc.disabled ? <IntlMessages id={bc.label} defaultMessage={bc.label} /> : <Link to={bc.path !== '' ? this.props.url + '/' + bc.path : this.props.url}><IntlMessages id={bc.label} defaultMessage={bc.label} onClick={() => this.context.navigate(bc.path !== '' ? this.props.url + '/' + bc.path : this.props.url)} /></Link>}</span>
          </Breadcrumb.Item>)
        })}
        {this.context.breadcrumb_ext.map(bc => {
          return (<Breadcrumb.Item key={i++}>
            {bc.disabled ? <span>{bc.label}</span> : <Link to={bc.path}><span>{bc.label}</span></Link>}
          </Breadcrumb.Item>)
        })}
      </Breadcrumb>
    )
  }

  render() {
    const collapsed = this.context.sbCollapsed && !this.context.sbOpenDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={this.toggleCollapsed}
            ><FontAwesomeIcon icon={["fas", "bars"]} /></button>
            <div className="breadCrumbWrapper">
              {this.displayBreadCrumb()}
            </div>
          </div>

          <ul className="isoRight">
            <li
              onClick={() => this.setState({ selectedItem: "role" })}
              className="isoRole"
            >
              <TopbarRole />
            </li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default Topbar;
