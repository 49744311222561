import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    key: '',
    path: '',
    product: 'r4',
    component: asyncComponent(() => import('../inquiry/risk4')),
    onMenu: true,
    label: 'dashboard',
    leftIcon: ["fal", "home-alt"],
    permissions: ['r4.inquiries.personal']
  },
  {
    key: 'inquiries',
    path: 'inquiries',
    product: 'r4',
    component: asyncComponent(() => import('../inquiry/risk4')),
    onMenu: false,
    label: 'inquiries',
    leftIcon: ["fal", "question-circle"],
    permissions: ['r4.inquiries.personal']
  },
  {
    key: 'inquiry',
    path: 'inquiry',
    regex: 'inquiry/:id',
    product: 'r4',
    component: asyncComponent(() => import('../../components/inquiry/inquiryInfo')),
    onMenu: false,
    label: 'inquiry',
    permissions: ['r4.inquiries.personal']
  },
  {
    key: 'clients',
    path: 'clients',
    product: 'r4',
    component: asyncComponent(() => import('../../components/client/clientList')),
    onMenu: true,
    label: 'clients',
    leftIcon: ["fal", "users"],
    permissions: ['r4.client.personal']
  },
];
export default routes;
