import styled from "styled-components";
import { palette, size, font } from "styled-theme";
import { transition, borderRadius } from "../../settings/style-util";

const AppHolder = styled.div`
  @media print {
    min-width: 0 !important;
    max-width: 100% !important;
  }
  
  min-width: 1366px;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette("primary", 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette("secondary", 1)};

    &.isoContentMainLayout {
      overflow: auto;
      position: relative;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette("border", 0)};
    height: 100%;
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .isomorphicContent  > div {
    height: 100%;
  }

  .ant-layout-footer {
    text-align: center;
    font-size: ${size('smallText')};
    background-color: ${palette('footer.background',0)};
    padding-bottom: 5px;
    padding-top: 5px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }


  /** Common FEF Styles **/
  .text-capitalize {
    text-transform: capitalize;
  }

  .text-capitalize-first::first-letter {
    text-transform:capitalize;
  }

  .spinLoaderCenter{
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center; 
  }

  .fefPageContentContainer {
    margin-left: 3em;
    margin-right: 3em;
  }

  .fefComponentContainer {
    position: relative;
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .fefComponentHeader {
    flex: 0 1 auto;
    span {
      font-size: ${size('mediumText')};
      font-weight: ${size('semibold')};
      color: ${palette('component.header', 0)};
    }
    span.sub-header{
      opacity: 0.5;
      font-weight: ${size('regular')};
    }
  }

  .fefComponentContent {
    flex: 1 1 auto;
    max-height: 100%;
    background: ${palette('component.background', 0)};
    border: .0625em solid ${palette('component.border', 0)};
    border-radius: 5px;
    padding: 1em 1em;
    margin-bottom: 1em;

  }

  .fefButton.ant-btn {
    color: ${palette('button.text',0)};
    border-color: ${palette('button.border',0)};
    text-transform:capitalize;
  }

  .fefButton.ant-btn:hover {
    color: ${palette('button.hover.text',0)}; 
    background-color: ${palette('button.hover.background',0)};
    border-color: ${palette('button.border',0)};
    text-transform:capitalize;
  }

  .fefButton.ant-btn.ant-btn.disabled,
  .fefButton.ant-btn.ant-btn[disabled],
  .fefButton.ant-btn.ant-btn.disabled:hover,
  .fefButton.ant-btn.ant-btn[disabled]:hover,
  .fefButton.ant-btn.ant-btn.disabled:focus,
  .fefButton.ant-btn.ant-btn[disabled]:focus,
  .fefButton.ant-btn.ant-btn.disabled:active,
  .fefButton.ant-btn.ant-btn[disabled]:active,
  .fefButton.ant-btn.ant-btn.disabled.active,
  .fefButton.ant-btn.ant-btn[disabled].active {
    color: ${palette('button.text',0)}; 
    background-color: ${palette('button.disabled.background',0)};
    border-color: ${palette('button.border',0)};
    cursor: not-allowed !important;
  }
  
  .fef-modal {
    .ant-modal-content {
        font-family: ${font('primary',0)};
    }

    .ant-modal-header {
        padding-top: 0.75em;
        padding-bottom: 0.75em;

        background-color: ${palette("sidebar.header", 0)};
        .ant-modal-title{
            color: ${palette("sidebar.lighttext", 0)};
        }
    }

    .ant-modal-close-x {
        height: 1em;
        line-height: 1em;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        color: ${palette("sidebar.lighttext", 0)};
    }
  }

  .fef-component-contact-container{
    display: grid;
    grid-template-columns: 300px 1fr;
    height: 100%;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      height: auto;
    }
  
    .fef-component-contact-bar {
      height: 100%;
      background: #ffffff;
      border: 1px solid ${palette("border", 0)};
      overflow: hidden;
      overflow-y: auto;
  
      @media only screen and (max-width: 767px) {
        width: auto !important;
        margin-bottom: 20px;
        min-width: 0 !important;
        max-width: 100% !important;
        flex: 0 !important;
      }
  
    }

    div.fef-component-contact-box {
      background-color: #ffffff;
      border: 0px solid ${palette("border", 0)};
      position: relative;
      padding: 0.5em 2em;
      overflow-x: hidden;
      overflow-y: auto;
  
      .ant-empty.ant-empty-normal {
        width: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        flex-direction: column;
        justify-content: center;
      }
  
      .isoContactBox {
        width: 100%;
        height: 100%;
      }
  
      .contactBoxScrollbar {
        height: calc(100vh - 225px);
      }
  
      .isoContactInfoContainer {
        padding-top: 1em;
        height: 100%;
        display: flex;
        flex-direction: column;
  
        .contactsLoadingActivity {
          text-align:center;
          margin: 1em 0;
          svg{
            color: ${palette("primary", 2)}
          }
        }
      }
  
      .isoContactViewTabsWrapper {
        height:100%;
  
        & > .ant-tabs-bar.ant-tabs-top-bar {
          height: 44px;
          padding-bottom: 16px;
        }
  
        & > .ant-tabs-top-content {
          max-height: calc(100% - 60px);
        }
      }
      .isoContactControl {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        padding: 30px;
        background-color: #ffffff;
  
        @media only screen and (max-width: 767px) {
          padding: 30px 20px;
        }
  
        button:not(.isoAddContactBtn) {
          font-size: 16px;
          color: ${palette("secondary", 0)};
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          background-color: #ffffff;
          outline: 0;
          padding: 0;
          border: 1px solid ${palette("border", 0)};
          margin-left: ${props =>
            props["data-rtl"] === "rtl" ? "inherit" : "-1px"};
          margin-right: ${props =>
            props["data-rtl"] === "rtl" ? "-1px" : "inherit"};
          cursor: pointer;
          ${borderRadius()};
          ${transition()};
  
          i {
            width: 100%;
          }
  
          span {
            display: none;
          }
  
          &:first-child {
            margin-left: ${props =>
              props["data-rtl"] === "rtl" ? "inherit" : "0"};
            margin-right: ${props =>
              props["data-rtl"] === "rtl" ? "0" : "inherit"};
          }
  
          &:hover {
            color: ${palette("primary", 0)};
            background-color: ${palette("grayscale", 7)};
          }
        }
  
        .isoAddContactBtn {
          background-color: ${palette("primary", 0)};
          border: 0;
          height: 30px;
          padding: 0 15px;
          margin-left: ${props =>
            props["data-rtl"] === "rtl" ? "inherit" : "auto"};
          margin-right: ${props =>
            props["data-rtl"] === "rtl" ? "auto" : "inherit"};
          ${borderRadius("3px")};
          ${transition()};
  
          span {
            font-size: 12px;
            font-weight: 400;
            padding: 0;
            text-transform: uppercase;
            color: #ffffff;
          }
  
          &:hover {
            background-color: ${palette("primary", 1)};
          }
        }
      }
  
      .contactsContainerHelp {
        height: 100%;
  
        & > section {
          height: 100%;
  
          & > div {
            padding-bottom: 2em;
          }
        }
  
        .contactsContainerHelpItemHeader {
          font-size: 1.25em;
          margin-top: 1em;
          margin-bottom: 0.25em;
          color: ${palette('text', 1)};
        }
  
        .isoContactIcon {
          color: ${palette('text', 3)};
        }
  
        .status-account-active {
          color: ${palette('dashboardcolors', 2)};
        }
  
        .status-invitesent {
          color: ${palette('dashboardcolors', 2)};
        }
      }
    }
  }
`;

export default AppHolder;
