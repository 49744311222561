import moment from 'moment';

export function getGreeting() {
    const currentHour = moment().hour();
    return (currentHour >= 12 && currentHour <=17) ? 'greeting.Afternoon'
      : (currentHour >= 18) ? 'greeting.Evening'
      : 'greeting.Morning'
  }

export function checkExists(obj, level, ...rest){
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkExists(obj[level], ...rest)
  }

export function getValue(path, obj) {
  const get = (p, o) =>
    p.reduce((xs, x) => (xs && xs[x] !== undefined) ? xs[x] : null, o);
  let fields = path.split(".");
  return get(fields, obj)
}