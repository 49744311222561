import React from 'react';
import { Link } from 'react-router-dom';
import { siteConfig } from '../../settings/config';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
        <div>
          <h3>
            <Link to="/dashboard"><img src={collapsed ? siteConfig.siteIcon : siteConfig.siteLogo} alt =""/></Link>
          </h3>
        </div>
    </div>
  );
};
