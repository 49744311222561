import React from 'react';
import Scrollbar from 'react-smooth-scrollbar';
export default React.forwardRef(({ id, style, children, className }, ref) => (
  <Scrollbar
    id={id}
    className={className}
    style={style}
    continuousScrolling={true}
    ref={ref}
  >
    {children}
  </Scrollbar>
));
