import React, { Component } from 'react';
import AppRouter from './AppRouter';
import AppTemplate from '../Base/AppTemplate.js'
import AppContext from '../../AppContext';

export class App extends Component {
  static contextType = AppContext;

  render() {
    const { url } = this.props.match;
    return (
      <AppTemplate {...this.props} >
        <AppRouter url={url} />
      </AppTemplate>

    );
  }
}

export default App
