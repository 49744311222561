import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from './appRoutes';
import asyncComponent from '../../helpers/AsyncFunc';
import AppContext from '../../AppContext';

const routes = Routes;

class AppRouter extends Component {
  static contextType = AppContext;

  render() {
    const { url, style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute => {
            const { key, path, regex, exact, permissions, component, children} = singleRoute;
            if (this.context.permissions[this.context.active_role] !== undefined 
                && permissions.some(r=> this.context.permissions[this.context.active_role].indexOf(r) >= 0)) {
              if(children) {
                return children.map(childRoute => {
                const { key, path, regex, exact, permissions, component} = childRoute;
                if (this.context.permissions[this.context.active_role] !== undefined 
                    && permissions.some(r=> this.context.permissions[this.context.active_role].indexOf(r) >= 0)) {
                  return (
                    <Route
                      exact={exact === false ? false : true}
                      key={key}
                      path={`${url}/${regex === undefined? path : regex}`}
                      component={component}
                    />
                  );
                } else{
                  return false;
                }
                });
              } else {
                return (
                  <Route
                    exact={exact === false ? false : true}
                    key={key}
                    path={`${url}/${regex === undefined? path : regex}`}
                    component={component}
                  />
                );
              }
            } else{
              return false;
            }
          })}
          <Route key="notfound" component={ asyncComponent(() => import('../notfound')) } />
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
