import { config } from "../settings/config";
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { deviceDetect } from 'react-device-detect';

export async function loginUser(username, password) {
  try {
    let res = await invokeApi({
      path: "/auth/login",
      method: "post",
      body: {
        'username': username,
        'password': password
      },
      unauth: true
    });

    if (setCurrentUser(res)) {
      let uuid = res['uuid'];

      let dt = getDeviceToken();

      if (dt !== null) {
        try {
          await invokeApi({
            path: "/auth/" + uuid + "/devices/token",
            method: "post",
            queryParams: { t: dt },
            body: deviceDetect()
          });
        } catch (e) { }
      }
      return true;
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }

}

export async function authUser() {
  let credentials = getCurrentUser();
  if (credentials !== null && Date.now() / 1000 < credentials.expires) {
    if ((credentials.expires - Date.now() / 1000) / 60 <= 30) {
      refreshUser(credentials)
    }
    return true;
  }
  return false;
}

async function refreshUser(credentials) {
  try {
    let res = await invokeApi({
      path: "/auth/refresh",
      method: "post",
      body: {
        'refresh_token': credentials.refresh_token,
      },
      unauth: false,
      refresh: true
    });
    setCurrentUser(res)
  } catch (e) {
    throw e;
  }
}

export function getCurrentUser() {
  let cred = localStorage.getItem('fef.auth.credentials')
  if (cred !== null) {
    cred = jwt.decode(localStorage.getItem('fef.auth.credentials'));
  }
  return cred;
}

export function getUserUuid() {
  return new Promise((resolve, reject) => {
    let credentials = getCurrentUser();
    resolve(credentials['uuid']);
  });
}

export function setCurrentUser(credentials) {
  if (credentials !== null & Date.now() / 1000 < credentials.expires) {
    localStorage.setItem('fef.auth.credentials', jwt.sign(credentials, config.jwt.sign_key));
    return true
  } else {
    return false
  }
}

export function removeAuthToken() {
  localStorage.removeItem('fef.auth.credentials');
}

export async function getUserState() {
  let credentials = getCurrentUser();

  var userState = {
    user_uuid: null,
    user_account: null
  }

  try {
    userState["user_uuid"] = credentials['uuid'];
    userState["roles"] = credentials['roles'] !== undefined ? credentials['roles'] : {};
    userState["permissions"] = credentials['permissions'] !== undefined ? credentials['permissions'] : {};
    let result = await invokeApi({
      path: "/auth/me",
      method: 'GET'
    });
    userState["user_info"] = result;
    if (Object.keys(credentials['roles'])[0] !== undefined) {
      userState["active_role"] = userState["user_info"]["default_role"] ? userState["user_info"]["default_role"] : Object.keys(credentials['roles'])[0];
      userState["defaultPage"] = credentials['roles'][userState["active_role"]].page;
    }
  } catch (e) {
    if (e.code === "UserNotFoundException") {
      removeAuthToken();
    } else {
      console.error(e);
    }
  }
  return userState;
}

function getDeviceToken() {
  return localStorage.getItem('fef.auth.device');
}

export function setDeviceToken(token) {
  localStorage.setItem('fef.auth.device', token);
}

export const axiosCancel = () => axios.CancelToken.source();

export async function invokeApi({
  path,
  method = "get",
  headers = {},
  queryParams = {},
  body,
  unauth = false,
  refresh = false,
  cancelToken = axios.CancelToken.source().token,
  onSuccess = false,
  onError = false
}) {
  if (refresh !== true && unauth !== true && !await authUser()) {
    window.location.reload();
  }

  var params = {}
  axios.defaults.headers.get['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  if (unauth === true) {
    params = {
      baseURL: config.apiGateway.AUTH !== undefined ? config.apiGateway.AUTH : config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data: body ? JSON.stringify(body) : body,
      headers: headers,
      cancelToken: cancelToken
    }
  } else {
    let credentials = getCurrentUser();
    params = {
      baseURL: config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data: body,
      headers: { 'x-fef-auth': credentials.access_token, ...headers },
      cancelToken: cancelToken
    }
  }
  var inError = false;
  try {
    var result = await axios(params).catch();
  } catch (error) {
    inError = true;
    if (!axios.isCancel(error)) {
      let e = error;
      if (error.response && error.response.data) {
        // The request was made and the server responded with an error
        e.data = {
          status_code: error.response.data.status_code ? error.response.data.status_code : error.request.status,
          message: error.response.data.message ? error.response.data.message : error.message
        }

      } else {
        // Something happened in setting up the request that triggered an Error
        e.data = {
          status_code: error.request.status,
          message: error.message
        }
      }

      if (onError !== false) {
        onError(e)
      } else {
        throw e;
      }
    }
  }
  if (!inError) {
    if (onSuccess !== false) {
      onSuccess(result.data)
    } else {
      return result.data
    }
  }
}
