import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, { getCurrentLanguage } from "./containers/LanguageSwitcher/config";
import { themeConfig, siteConfig } from "./settings/config";
import DashAppHolder from "./dashAppStyle";
import { Helmet } from "react-helmet";

import PublicRoutes from "./router";
import { build_route_map } from './containers/Base/appRoutes';
import { authUser, loginUser, getUserState, removeAuthToken, setDeviceToken, axiosCancel } from './helpers/authHelper';
import { checkExists } from './helpers/utilities';

import moment from 'moment';
import numeral from 'numeral';
import * as d3 from 'd3';
import d3enUS from 'd3-format/locale/en-US';
import d3frCA from 'd3-format/locale/fr-CA';

import 'moment/locale/fr.js';
import 'numeral/locales/fr-ca.js';

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faPuzzlePiece, faProjectDiagram, faUserAlt, faEye as fasEye, faCircle, faCaretDown, faCaretUp, faExclamationTriangle as fasExclamationTriangle,
  faShieldCheck, faUserCheck as fasUserCheck, faClipboardCheck
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch as rfaCircleNotch, faSync } from '@fortawesome/pro-regular-svg-icons';
import {
  faUser, faUsers, faUserCircle, faTachometerAverage, faFile, faFileDownload, faPaperclip, faFileExclamation, faQuestionCircle, faFileChartLine, faChartNetwork, faSignal,
  faExclamationTriangle, faCheckCircle, faExclamationCircle, faConstruction, faEdit, faFilePlus, faEye, faBoxes, faCircleNotch, faUserCheck, faUserTimes, faSitemap, faPencil,
  faComment, faCommentAltLines, faCommentAltPlus, faSearch, faTrashAlt, faFileAlt, faHomeAlt, faReply, faTimes, faPlusCircle, faUserFriends, faExchange, faUserChart, faChartPieAlt,
  faFileInvoiceDollar, faTimesCircle, faParkingCircle, faChevronDown, faEnvelope, faLink, faRegistered, faDoorOpen, faDoorClosed, faKey, faCheck, faMoneyCheckAlt, faBookReader, faBook,
  faChevronLeft, faChevronRight, faPlus, faMinus
} from '@fortawesome/pro-light-svg-icons';

library.add(faBars, faPuzzlePiece, faProjectDiagram, faUserAlt, fasEye, faCircle, faExclamationCircle, faCaretDown, faCaretUp, fasExclamationTriangle,
  faShieldCheck, fasUserCheck, faClipboardCheck,
  rfaCircleNotch, faSync,
  faUser, faUsers, faUserCircle, faTachometerAverage, faFile, faQuestionCircle, faFileDownload, faPaperclip, faFileExclamation, faFileChartLine, faChartNetwork, faSignal,
  faExclamationTriangle, faCheckCircle, faConstruction, faEdit, faFilePlus, faEye, faBoxes, faCircleNotch, faUserCheck, faUserTimes, faSitemap, faPencil,
  faComment, faCommentAltLines, faCommentAltPlus, faSearch, faTrashAlt, faFileAlt, faHomeAlt, faReply, faTimes, faPlusCircle, faUserFriends, faExchange, faUserChart, faChartPieAlt,
  faFileInvoiceDollar, faTimesCircle, faParkingCircle, faChevronDown, faEnvelope, faLink, faRegistered, faDoorOpen, faDoorClosed, faKey, faCheck, faMoneyCheckAlt, faBookReader, faBook,
  faChevronLeft, faChevronRight, faPlus, faMinus)

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

function getView(width) {
  let newView = 'MobileView';
  if (width > 1500) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    // defaultPage should be controlled via backend so different users types can have different default pages
    this.state = {
      defaultPage: siteConfig.defaultPage,
      isAuthenticating: true,
      isAuthenticated: false,
      user_state: null,
      state_order: ['sq', 'token'],
      view: getView(window.innerWidth),
      height: window.innerHeight,
      sbCollapsed: window.innerWidth > 1500 ? false : true,
      sbOpenDrawer: false,
      sbCurrent: [],
      sbOpenKeys: [],
      active_role: "all",
      roles: {},
      permissions: {},
      breadcrumb: [],
      breadcrumb_ext: [],
      printView: false,
      activeRegion: "all"
    }
  }

  async componentDidMount() {
    await this.update_user_state();
    window.addEventListener('beforeprint', (event) => { this.printSetup(event) })
    window.addEventListener('afterprint', (event) => { this.printSetup(event) })
    this.setState({ isAuthenticating: false, loading_state: 'complete', breadcrumb: this.buildBreadCrumb() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.sbCurrent) !== JSON.stringify(this.state.sbCurrent)) {
      this.setState({ breadcrumb: this.buildBreadCrumb() });
    }
  }

  printSetup(event) {
    this.setState({ printView: event.type === "beforeprint" ? true : false })
  }

  buildBreadCrumb = () => {
    var breadcrumb = []
    if (window && window.location) {
      const pathSnippets = window.location.pathname.split('/');

      let routes = build_route_map();

      let c_routes = routes[pathSnippets[1]] !== undefined ? routes[pathSnippets[1]] : routes;

      for (let i = 0; i < pathSnippets.length; i++) {
        if (c_routes[pathSnippets[i]] !== undefined) {
          breadcrumb.push({ label: c_routes[pathSnippets[i]].label, path: c_routes[pathSnippets[i]].path, disabled: c_routes[pathSnippets[i]].bcDisabled !== undefined ? c_routes[pathSnippets[i]].bcDisabled : false })
          c_routes = c_routes[pathSnippets[i]]['children'];
        }
      }
    }
    return breadcrumb;
  }

  overrideBreadCrumb = (breadcrumb) => {
    this.setState({ breadcrumb_ext: breadcrumb })
  }

  update_user_state = async () => {
    if (await authUser()) {
      var userState = await getUserState();
      this.setState({
        isAuthenticated: true, loading_state: 'user_data', ...userState,
        activeRegion: (checkExists(userState, 'user_info', 'default_region') && userState.user_info.default_region !== null ? userState.user_info.default_region : 'all')
      });
    }
  }

  user_has_authenticated = (value) => {
    this.setState({ isAuthenticated: value })
  }

  signoutUser = () => {
    removeAuthToken();
    this.setState({ isAuthenticated: false });
  }

  changeActiveRole = role => {
    this.setState({ active_role: role });
  }

  // Function to handle sidebar and topbar nav.
  toggleResize = (width, height) => {
    let view = getView(width);
    this.setState({
      view: view,
      sbCollapsed: (view !== 'DesktopView'),
      height: height
    })
  }

  toggleCollapsed = () => {
    this.setState({ sbCollapsed: !this.state.sbCollapsed })
  }

  toggleOpenDrawer = () => {
    this.setState({ sbOpenDrawer: !this.state.sbOpenDrawer })
  }

  changeCurrent = (current) => {
    this.setState({ sbCurrent: current });
  }

  changeOpenKeys = (openKeys) => {
    this.setState({ sbOpenKeys: openKeys });
  }

  currentLanguage = () => {
    return getCurrentLanguage(config.defaultLanguage || "english").locale
  }

  check_icon_imported = (is) => {
    return icon({ prefix: is[0], iconName: is[1] }) !== undefined
  }
  capitalizeStr = (s) => {
    let str = '';
    s.split(" ").map((ss, i) => str += ss.charAt(0).toUpperCase() + ss.slice(1) + ' ')
    return str.trim()
  }

  changeRegion = (region) => {
    this.setState({ activeRegion: region });
  }

  currentProduct = () => {
    return window.location.pathname.split('/')[1]
  }

  hasPermission = (permission) => {
    return this.state.permissions[this.state.active_role] !== undefined
      && this.state.permissions[this.state.active_role].indexOf(permission) !== -1 ? true : false
  }

  render() {
    moment.locale(this.currentLanguage() === 'en' ? 'en' : 'fr-ca');
    numeral.locale(this.currentLanguage() === 'en' ? 'en' : 'fr-ca');
    d3.formatDefaultLocale(this.currentLanguage() === 'en' ? d3enUS : d3frCA)

    // Monkeypatch Numeral to support fr-ca currency
    if (numeral.patched === undefined) {
      numeral.old_curr = numeral.formats.currency.format;
      numeral.formats.currency.format = (value, format, roundingFunction) => {
        let output;

        let svg = false;
        let string = false;

        if (/\$s/.test(format)) {
          svg = true;
          format = format.replace(/\$s/, "$");
        }
        if (/s\$/.test(format)) {
          string = true;
          format = format.replace(/s\$/, "$");
        }

        let match = /(.*)([\s]*)(\$)([\s]*)([\d,.]*)(.*)$/.exec(format);

        let locale = numeral.locales[numeral.options.currentLocale];

        if (this.currentLanguage() === 'fr') {
          format = match[1] + match[5] + match[4] + match[3] + match[2] + match[6]
        }

        output = numeral.old_curr(value, format, roundingFunction);

        if (svg) {
          let split = output.split(locale.currency.symbol);
          if (string) {
            output = `<tspan>${split[0]}<tspan class="small-symbol">${locale.currency.symbol}</tspan>${split[1] !== undefined ? split[1] : ''}</tspan>`;
          } else {
            output = (<tspan>{split[0]}<tspan className="small-symbol">{locale.currency.symbol}</tspan>{split[1]}</tspan>);
          }
        } else {
          let split = output.split(locale.currency.symbol);
          if (string) {
            output = `${split[0]}<span class="small-symbol">${locale.currency.symbol}</span>${split[1] !== undefined ? split[1] : ''}`;
          } else {
            output = (<>{split[0]}<span className="small-symbol">{locale.currency.symbol}</span>{split[1]}</>);
          }
        }

        return output;
      }
      numeral.patched = true;
    }

    let context = {
      ...this.state,
      app_container: this.app_container,
      login_user: loginUser,
      signout_user: this.signoutUser,
      updateUserStatus: this.update_user_state,
      user_has_authenticated: this.user_has_authenticated,
      setDeviceToken: setDeviceToken,
      toggleCollapsed: this.toggleCollapsed,
      toggleOpenDrawer: this.toggleOpenDrawer,
      toggleResize: this.toggleResize,
      changeCurrent: this.changeCurrent,
      changeOpenKeys: this.changeOpenKeys,
      overrideBreadcrumb: this.overrideBreadCrumb,
      changeActiveRole: this.changeActiveRole,
      checkIconImported: this.check_icon_imported,
      capitalizeStr: this.capitalizeStr,
      changeRegion: this.changeRegion,
      currentProduct: this.currentProduct,
      axiosCancel: axiosCancel,
      hasPermission: this.hasPermission
    }

    return !this.state.isAuthenticating && (
      <ConfigProvider locale={currentAppLocale.antd} getPopupContainer={trigger => trigger !== undefined ? trigger.parentNode : document.body}>
        <IntlProvider locale={currentAppLocale.locale} defaultLocale={'en-US'} messages={currentAppLocale.messages}>
          <ThemeProvider theme={themes[themeConfig.theme]}>
            <DashAppHolder>
              <Helmet>
                <title>{siteConfig.title}</title>
              </Helmet>
              <BrowserRouter>
                <PublicRoutes context={context} />
              </BrowserRouter>
            </DashAppHolder>
          </ThemeProvider>
        </IntlProvider>
      </ConfigProvider>
    );
  }
}

export default App;
