import r7 from '../r7/appRoutes.js';
import r4 from '../r4/appRoutes.js';

const routes = [...r7, ...r4];
export default routes;

export const build_route_map = () => {

  var build_map_par = (item) =>{
    let chdrn =  {}
    if (item.children !== undefined){
      for (let c of item.children){
        chdrn[c.key] = build_map_par(c);
      }
    }
    return {...item, children: chdrn}
  }

  var route_map = {};
  for (let r of routes) {
    if(route_map[r.product] === undefined){
      route_map[r.product] = {}
    }
    route_map[r.product][r.key] = build_map_par(r);
    
  }
  return route_map
}