import React from 'react'; 
import { Typography, Collapse } from 'antd'; 
import { injectIntl } from 'react-intl'; 
import IntlMessages from '../components/utility/intlMessages'; 
 
const { Title, Paragraph } = Typography; 
const { Panel } = Collapse; 
 
class ErrorBoundary extends React.Component { 
    constructor(props) { 
      super(props); 
      this.state = { hasError: false, error: false, errorInfo: false }; 
    } 
   
    static getDerivedStateFromError(error) { 
      // Update state so the next render will show the fallback UI. 
      return { hasError: true }; 
    } 
   
    componentDidCatch(error, errorInfo) { 
      // You can also log the error to an error reporting service 
      console.error(error); 
      this.setState({ error: error, errorInfo: errorInfo}) 
    } 
   
    render() { 
      if (this.state.hasError) { 
        // You can render any custom fallback UI 
        return ( 
          <div style={{padding: "1em"}}> 
              <Title level={4} type="secondary"><IntlMessages id={'error.boundry.default'} /></Title> 
              <Collapse bordered={false} defaultActiveKey={[]} style={{background: "transparent", border: 0,}}> 
                <Panel header={<IntlMessages id={'error.boundry.details'} />} key="1" style={{background: "transparent", border: 0, borderBottom: "0px solid transparent", opacity: 0.7}}> 
                  <Paragraph>{this.state.error.toString()}</Paragraph> 
                  <Paragraph>{this.state.error.stack}</Paragraph> 
                </Panel> 
              </Collapse> 
          </div> 
        ); 
      } 
   
      return this.props.children;  
    } 
} 
 
export default injectIntl(ErrorBoundary); 